import type { MaybeRef, Ref } from 'vue'
import type { RentReviewTable, RentReviewType } from '../types'
import { convertToRentReviewTable } from '../utils'
import { FieldUsage, YearType } from '@manager'

export const useRentReviewData = (
  initialValue: MaybeRef<RentReviewTable[]>,
  rentReviewType: Ref<RentReviewType>,
  objectPath: string,
) => {
  const { onSubmit: _submit } = useManagerNodeSubmit(FieldUsage.INTERACTIVE)

  const {
    data: items,
    filteredData: _filteredItems,
    add: _add,
    updateById,
    removeById,
    getById,
  } = useTableData<RentReviewTable>(initialValue, {
    path: objectPath,
    initialItem: () => {
      return {
        date: null,
        method: null,
        fixedPercent: null,
        fixedAmount: null,
        cpiPercent: null,
        cpiCap: null,
        cpiCollar: null,
        marketReviewDate: null,
        marketReviewCap: null,
        marketReviewCollar: null,
        marketReviewAmount: null,
        greaterOf: null,
        lesserOf: null,
        formula: null,
        rent: null,
      }
    },
    onCalculateProperty: (item, key) => {
      if (key === 'fixedPercent' && item.fixedPercent)
        return item.fixedPercent / 100

      return item[key]
    },
    onAdd: () => {
      _submit()
    },
    onUpdate: () => {
      _submit()
    },
  })

  let initialItem: RentReviewTable | undefined = undefined
  const { generate } = useDatesGenerator(
    {},
    {
      onAdd: (index, { startDate }) => {
        const _initialItem =
          initialItem?.__duplicate || index === 0 ? initialItem : {}

        // @ts-expect-error - `__status` is from `useRepeater`
        _add({
          ..._initialItem,
          date: startDate.toISOString(),
          __status: REPEATER_ITEM_STATUS.GENERATED,
        })
      },
      onRemove: () => {
        // In this case, we don't want to remove on generate.
      },
    },
  )

  const filteredItems = computed(() =>
    _filteredItems.value.map(convertToRentReviewTable),
  )

  const add = (item: RentReviewTable) => {
    if (filteredItems.value.length > 0) {
      return _add(item)
    }

    // Generate
    initialItem = item

    generate(YearType.LEASE_YEAR, {
      startDate: item.date,
      endDate: rentReviewType.value.leaseEndDate,
    })

    initialItem = undefined
  }

  const isEmpty = computed(() => filteredItems.value.length === 0)

  return {
    items,
    filteredItems,
    add,
    updateById,
    removeById,
    getById,
    isEmpty,
  }
}
