<script setup lang="ts">
import type { ComputedRef } from 'vue'
import { useModal } from 'vue-final-modal'
import type {
  IManagerTableEmits,
  IManagerTableProps,
} from '@manager/components/Group/Table/types'
import {
  type INodeObject,
  joinPath,
  parseSizeToGrid,
  type RepeaterItem,
} from '@manager'
import { ReviewFormRentReviewTableForm } from '#components'
import type { RentReviewTable, RentReviewType, RentReviewForm } from './types'
import { useRentReviewData } from './composables'
import {
  convertToIRentIncreaseTableForm,
  convertToRentReviewAPI,
} from './utils'

const props = withDefaults(defineProps<IManagerTableProps>(), {
  parentValue: () => ({}),
  parentPath: '',
})
const emit = defineEmits<IManagerTableEmits>()

const colSize = computed(() => parseSizeToGrid(props.node.size))

const objectNode = props.node.nodes[0] as INodeObject
const objectPath = joinPath(props.parentPath, objectNode.name)

const [parentValue, dataModelValue] = useObjectVModel(
  {
    ...props,
    node: objectNode,
  },
  'parentValue',
  emit,
)

const { dateFormat: divisionDateFormat } = useDivisions()
const { data: lease, dateFormat: leaseDateFormat } = useCurrentLease()

const { formValue } = useManagerFormValue<RentReviewForm>()
const rentReviewType = computed(
  () => formValue.value?.rentReviewType?.data?.[0],
)
const leaseStartDate = computed(() => rentReviewType.value?.leaseStartDate)
const leaseEndDate = computed(() => rentReviewType.value?.leaseEndDate)
const baseRent = computed(() => rentReviewType.value?.baseRent)

const { filteredItems, add, updateById, removeById, getById, isEmpty } =
  useRentReviewData(
    dataModelValue as ComputedRef<RentReviewTable[]>,
    rentReviewType,
    objectPath,
  )

const itemValue = ref<RepeaterItem<RentReviewTable>>()
const {
  open: openModal,
  close,
  patchOptions,
} = useModal({
  component: ReviewFormRentReviewTableForm,
  attrs: {
    isManager: true,
    // @ts-expect-error - Reactivity
    commencementDate: leaseStartDate,
    // @ts-expect-error - Reactivity
    expiryDate: leaseEndDate,
    // @ts-expect-error - Reactivity
    isFirstItem: isEmpty,
    // @ts-expect-error - Reactivity
    disabledDates: computed(() =>
      filteredItems.value
        .map((item) => item.date)
        .filter((date) => itemValue?.value?.Date !== date),
    ),
    // @ts-expect-error - Reactivity
    settings: computed(() => lease.value?.settings),
    onConfirm: (item) => {
      try {
        // Updating
        if (itemValue.value) {
          const converted = convertToRentReviewAPI(item)
          converted.rent = null
          updateById(itemValue.value.__id, converted)
          // Adding
        } else {
          add(convertToRentReviewAPI(item))
        }
      } catch (e) {
        console.error(e)
      }
      close().then(() => {
        itemValue.value = undefined
        patchOptions({
          attrs: {
            rentIncrease: undefined,
          },
        })
      })
    },
    onClose: () => {
      close().then(() => {
        itemValue.value = undefined
        patchOptions({
          attrs: {
            rentIncrease: undefined,
          },
        })
      })
    },
  },
})

const onAdd = () => {
  openModal()
}
const onEdit = (id: string) => {
  const item = getById(id)
  if (item) {
    itemValue.value = item
    patchOptions({
      attrs: {
        rentIncrease: convertToIRentIncreaseTableForm(item),
      },
    })
    openModal()
  }
}

const confirm = useConfirm()
const onRemove = (id: string) => {
  confirm.require({
    title: 'Delete rent increase',
    body: 'Are you sure you want to delete this rent increase?',
    submitButton: 'Delete',
    confirm: () => removeById(id),
  })
}
</script>

<template>
  <div :class="[colSize, 'grid grid-flow-row grid-cols-1 gap-2']">
    <ReviewFormRentReviewTable
      :data="filteredItems"
      :expiry-date="leaseEndDate"
      :commencement-date="leaseStartDate"
      :lease-date-format="leaseDateFormat"
      show-rent
      show-method
      @add="onAdd"
      @edit="({ __id }) => onEdit(__id)"
      @delete="({ __id }) => onRemove(__id)"
    >
      <template #header>
        <span
          v-if="filteredItems.length"
          class="flex w-full justify-end gap-1 text-sm"
        >
          <span class="text-gray-300"> Initial base rent: </span>
          <CurrencyDisplay :value="Number(baseRent)" show-symbol />
        </span>
      </template>
    </ReviewFormRentReviewTable>
  </div>
</template>
